import React from 'react';
import { Link } from 'react-router-dom';
import style from './Product.module.css';

const Product = ({product}) => {
    const { _id , name , img , price } = product;
    return (
        <div id={style.product_container}>
            <img id={style.image} src={img} alt='' />
            <p id={style.name}>{name}</p>
            <p id={style.price}>Price: {price}</p>
            <Link id={style.link} to={`/product_details/${_id}`} >View Details</Link>
        </div>
    );
};

export default Product;