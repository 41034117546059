import React, { useState } from 'react';
import style from './AddMember.module.css';

const AddMember = () => {
    const [ name , setName ] = useState('');
    const [ image , setImage ] = useState('');
    const [ designation , setDesignation ] = useState('');
    const [ mobile , setMobile ] = useState('');
    const [ email , setEmail ] = useState('');
    const [ message , setMessage ] = useState('');
    const [ description , setDescription ] = useState('');
    const [ aboutImage , setAboutImage ] = useState('');

    const onOptionChange = e => {
        setDesignation(e.target.value)
    }

    const handle_add_about_image = () => {
        const item = { img : aboutImage };
        fetch('https://i4technologiesbd.vercel.app/add_about/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(item),
        })
        .then((response) => response.json())
        .then((data) => {
            window.location.reload();
        })
    }

    const handle_update_about = event => {
        event.preventDefault();
        fetch("https://i4technologiesbd.vercel.app/delete_about/", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.json())
        .then( (data) => {
            handle_add_about_image();
        } )
    }

    const handle_add_to_db = event => {
        event.preventDefault();
        const item = {
            name: name, image: image, designation: designation, mobile: mobile, email: email, message: message, description: description
        }
        fetch('https://i4technologiesbd.vercel.app/add_member/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(item),
        })
        .then((response) => response.json())
        .then((data) => {
            window.location.reload();
        })
    }

    return (
        <div>
            <div id={style.edit_about}>
                <div id={style.about_image_container}>
                    <img src={aboutImage} alt="About Image Here"/>
                </div>
                <div id={style.add_about_image}>
                    <p>Enter Image URL Here:</p>
                    <input id={style.about_image_input} onChange={ e => setAboutImage(e.target.value) } />
                    <button onClick={handle_update_about} id={style.about_button}>Add New Image & Delete Previous Image</button>
                </div>
            </div>
            <div id={style.add_member}>
                <div id={style.output}>
                    <div id={style.up}>
                        <div id={style.left}>
                            <img src={image} alt="Member Image is Here" />
                        </div>
                        <div id={style.right}>
                            <p id={style.name}>Name: {name}</p>
                            <p id={style.designation}>Designation: {designation}</p>
                            <p id={style.mobile}>Mobile: {mobile}</p>
                            <p id={style.email}>Email: {email}</p>
                            <p id={style.massage}>Massage: {message}</p>
                        </div>
                    </div>
                    <div id={style.down}>
                        <p id={style.details_title}>Personal details: </p>
                        <img src={description} alt="personal details" />
                    </div>
                </div>
                <div id={style.input_form}>
                    <div className={style.input_section}>
                        <p className={style.input_title}>Enter the member name here </p>
                        <input onChange={(e) => setName(e.target.value)}></input>
                    </div>
                    <div className={style.input_section}>
                        <p className={style.input_title}>Enter member image here </p>
                        <input onChange={(e) => setImage(e.target.value)}></input>
                    </div>
                    <div className={style.input_section}>
                        <p className={style.input_title}>Select member's designation here </p>

                        <div className={style.designation_option}>
                            <input type="radio" name="designation" value="Founder" id="Founder" checked={designation === "Founder"} onChange={onOptionChange} />
                            <label htmlFor="Owner">Founder</label>
                        </div>

                        <div className={style.designation_option}>
                            <input type="radio" name="designation" value="Advisor" id="Advisor" checked={designation === "Advisor"} onChange={onOptionChange} />
                            <label htmlFor="Advisor">Advisor</label>
                        </div>

                        <div className={style.designation_option}>
                            <input type="radio" name="designation" value="1st class officer" id="1st class officer" checked={designation === "1st class officer"} onChange={onOptionChange} />
                            <label htmlFor="1st class officer">1st class officer</label>
                        </div>

                        <div className={style.designation_option}>
                            <input type="radio" name="designation" value="2nd class officer" id="2nd class officer" checked={designation === "2nd class officer"} onChange={onOptionChange} />
                            <label htmlFor="2nd class officer">2nd class officer</label>
                        </div>

                        <div className={style.designation_option}>
                            <input type="radio" name="designation" value="3rd class officer" id="3rd class officer" checked={designation === "3rd class officer"} onChange={onOptionChange} />
                            <label htmlFor="3rd class officer">3rd class officer</label>
                        </div>

                        <div className={style.designation_option}>
                            <input type="radio" name="designation" value="4th class officer" id="4th class officer" checked={designation === "4th class officer"} onChange={onOptionChange} />
                            <label htmlFor="4th class officer">4th class officer</label>
                        </div>

                        <div className={style.designation_option}>
                            <input type="radio" name="designation" value="Part time employee" id="part-time-employee" checked={designation === "Part time employee"} onChange={onOptionChange} />
                            <label htmlFor="Part time employee">Part Time Employee</label>
                        </div>

                    </div>

                    <div className={style.input_section}>
                        <p className={style.input_title}>Enter member Mobile No here </p>
                        <input onChange={(e) => setMobile(e.target.value)}></input>
                    </div>
                    <div className={style.input_section}>
                        <p className={style.input_title}>Enter member Email here </p>
                        <input onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                    <div className={style.input_section}>
                        <p className={style.input_title}>Enter Optional Message here </p>
                        <textarea onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className={style.input_section}>
                        <p className={style.input_title}>Enter member description here </p>
                        <input onChange={(e) => setDescription(e.target.value)}></input>
                    </div>

                    <button id={style.button} onClick={handle_add_to_db}>Add to DataBase</button>

                </div>
            </div>
        </div>
    );
};

export default AddMember;