import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home/Home';
import ProductInShop from './components/pages/ProductInShop/ProductInShop';
import ProductDetailsInShop from './components/pages/ProductDetailsInShop/ProductDetailsInShop';
import About from './components/pages/About/About';
import Contact from './components/pages/Contact/Contact';
import Shop from './Layout/Shop/Shop';
import Admin from './Layout/Admin/Admin';
import LogIn from './components/pages/LogIn/LogIn';
import AddProduct from './components/pages/AddProduct/AddProduct';
import ViewProductAdmin from './components/pages/ViewProductAdmin/ViewProductAdmin';
import CheckProduct from './components/pages/CheckProduct/CheckProduct';
import PrivateRoute from './components/pages/PrivateRoute/PrivateRoute';
import AddMember from './components/pages/AddMember/AddMember';
import MemberDetails from './components/pages/MemberDetails/MemberDetails';
import CheckMember from './components/pages/CheckMember/CheckMember';
import ViewMemberAdmin from './components/pages/ViewMemberAdmin/ViewMemberAdmin';
import AddPicture from './components/pages/AddPicture/AddPicture';
import ViewPictureAdmin from './components/pages/ViewPictureAdmin/ViewPictureAdmin';

function App() {
  const app_router = createBrowserRouter([
    { path: '/' , element: <Shop></Shop>, children: [
      { path: '/', loader: async() => { return fetch("https://i4technologiesbd.vercel.app/all_image") }, element: <Home></Home> },
      { path: '/products/:id', element: <ProductInShop></ProductInShop> },
      { path: '/product_details/:id',
        loader: async({params}) => {
          return fetch(`https://i4technologiesbd.vercel.app/product_details_in_shop/${params.id}/`)
        },
        element: <ProductDetailsInShop></ProductDetailsInShop>
      },
      { path: '/member_details/:id',
        loader: async({params}) => {
          return fetch(`https://i4technologiesbd.vercel.app/member_details/${params.id}/`)
        },
        element: <MemberDetails></MemberDetails>
      },
      { path: '/about', element: <About></About> },
      { path: '/contact', element: <Contact></Contact> },
    ] },
    { path: '/login', element: <LogIn></LogIn>},
    { path: '/admin', element: <PrivateRoute><Admin></Admin></PrivateRoute>,
      children: [
        {
          path:'/admin/',
          loader: async() => {
            return fetch('https://i4technologiesbd.vercel.app/allproducts');
          },
          element: <ViewProductAdmin></ViewProductAdmin>
        },
        {
          path:'/admin/see_member',
          loader: async() => {
            return fetch('https://i4technologiesbd.vercel.app/allmember');
          },
          element: <ViewMemberAdmin></ViewMemberAdmin>
        },
        {
          path:'/admin/see_picture',
          loader: async() => {
            return fetch('https://i4technologiesbd.vercel.app/all_image');
          },
          element: <ViewPictureAdmin></ViewPictureAdmin>
        },
        {path:'/admin/add_product', element: <AddProduct></AddProduct>},
        {path:'/admin/add_member', element: <AddMember></AddMember>},
        {path:'/admin/add_picture', element: <AddPicture></AddPicture>},
        {
          path:'/admin/check_product/:id',
          loader: async({params}) => {
            return fetch(`https://i4technologiesbd.vercel.app/product_details_in_shop/${params.id}/`);
          },
          element: <CheckProduct></CheckProduct>
        },
        {
          path:'/admin/check_member/:id',
          loader: async({params}) => {
            return fetch(`https://i4technologiesbd.vercel.app/member_details/${params.id}/`);
          },
          element: <CheckMember></CheckMember>
        }
      ]
    }
  ]);
  return (
    <div className="App">
      <RouterProvider router={(app_router)}></RouterProvider>
    </div>
  );
}

export default App;
