import React from 'react';
import { Link } from 'react-router-dom';
import style from './Member.module.css';

const Member = ({member}) => {
    const { name , image, designation, _id } = member;
    return (
        <div id={style.member}>
            <img id={style.image} src={image} alt='' />
            <p id={style.name}>{name}</p>
            <p id={style.designation}>{designation}</p>
            <Link id={style.link} to={`/member_details/${_id}`} >View Details</Link>
        </div>
    );
};

export default Member;