import React, { useContext } from 'react';
import { AuthContext } from '../../../Context/UserContext';
import style from './LogIn.module.css';

const LogIn = () => {
    const { LogIn } = useContext(AuthContext);
    const handle_Log_In = () => {
        LogIn()
        .then(result => console.log(result.user.email))
        .catch( err => console.error(err) )
    }
    return (
        <div id={style.login}>
            <button id={style.button} onClick={handle_Log_In}>Continue with Google</button>
        </div>
    );
};

export default LogIn;