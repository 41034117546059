import React from 'react';
import { Helmet } from 'react-helmet';
import { useLoaderData } from 'react-router-dom';
import phone_icon from '../../../images/icons/phone.svg';
import style from './ProductDetailsInShop.module.css';

const ProductDetailsInShop = () => {
    const details = useLoaderData();
    const {img, name, price, description, brand, model, automation, optional_message, communication, electrical, mechanical, civil, sensor, tools, seodescription, seokeywords} = details;

    return (
        <div id={style.container}>
            <Helmet>
            <meta name="description" content={seodescription} ></meta>
            <meta name="keywords" content={seokeywords}></meta>
            </Helmet>
            <div id={style.leftt_side}></div>
            <div id={style.info_container}>
                <div id={style.info_up}>
                    <div id={style.image_container}>
                        <img src={img} alt='' />
                    </div>
                    <div id={style.basic_info} >
                        <p id={style.product_name}>{name}</p>
                        <p id={style.product_model}>Brand: {brand}</p>
                        <p id={style.product_model}>Model: {model}</p>
                        
                        <p id={style.product_price}>Price: {price}</p>
                        <div id={style.contact_box}>
                            <img src={phone_icon} alt='' />
                            <p>+88 01918000690</p>
                        </div>
                        <p id={style.optional_message}>{optional_message}</p>
                    </div>
                </div>
                <div id={style.seo_info}>
                    <hr className={style.hr_line} />
                    <div id={style.categori_container}>
                        <p id={style.categori_title}>Category :</p>
                        {
                            automation ? <p className={style.categori}>I.4 Technology & Industrial Automation</p> : null
                        }
                        {
                            communication ? <p className={style.categori}>Electronic & Communication Engineering Equipments & Trainer kits</p> : null
                        }
                        {
                            electrical ? <p className={style.categori}>Electrical Engineering Equipments & Trainer kits</p> : null
                        }
                        {
                            mechanical ? <p className={style.categori}>Mechanical Engineering Equipment & Trainer kits</p> : null
                        }
                        {
                            civil ? <p className={style.categori}>Civil Engineering Equipments & Trainer kits</p> : null
                        }
                        {
                            sensor ? <p className={style.categori}>Measuring Equipments & Sensor</p> : null
                        }
                        {
                            tools ? <p className={style.categori}>Tools & Devices</p> : null
                        }
                    </div>
                    <hr className={style.hr_line} />
                    <div id={style.categori_container}>
                        <p id={style.categori_title}>Keyword :</p>
                        <p className={style.seo_paragraph}>{seokeywords}</p>
                    </div>
                    <hr className={style.hr_line} />
                </div>
                <div id={style.info_down}>
                    <p id={style.description_title}>Description:</p>
                    <img src={description} alt={name} />
                </div>
            </div>
            <div id={style.right_side}></div>
        </div>
    );
};

export default ProductDetailsInShop;