import React, { useState } from 'react';
import style from './ShopHeader.module.css';
import phone_icon from '../../../images/icons/phone.svg';
import mail_icon from '../../../images/icons/mail.svg';
import fb_icon from '../../../images/icons/facebook.svg';
import twit_icon from '../../../images/icons/twitter.svg';
import inst_icon from '../../../images/icons/instagram.svg';
import lnkd_icon from '../../../images/icons/linkedin.svg';
import { Link} from 'react-router-dom';
import { HomeIcon, ArrowDownIcon, InformationCircleIcon, MapPinIcon } from '@heroicons/react/24/solid';
import logo from '../../../images/logo.svg';

const ShopHeader = () => {
    const  donotShow = {display: "none"};
    const  show = {display: "inline"};
    const  onfocus = { color: "#FFFFFF", backgroundColor: "#054ADA" };
    const   onblur = { color: "#054ADA", backgroundColor: "#FFFFFF" };
    const [ category_container_style, setCategory_container_style ] = useState(donotShow);
    const [ home_style, setHome_style ] = useState(onblur);
    const [ product_style, setProduct_style ] = useState(onblur);
    const [ about_style, setAbout_style ] = useState(onblur);
    const [ contact_style, setContact_style ] = useState(onblur);

    return (
        <div id={style.shop_header}>
            <div id={style.up}>
                <div id={style.up_left}>
                    <div className={style.contact_container}>
                        <img src={phone_icon} alt='' />
                        <p>+88 01918000690</p>
                    </div>
                    <div className={style.contact_container}>
                        <img src={mail_icon} alt='' />
                        <p>i4technologiesbd@gmail.com</p>
                    </div>
                </div>
                <div id={style.up_right}>
                    <a className={style.social_link} href="https://www.facebook.com/" target="blank"><img src={fb_icon} alt='' /></a>
                    <a className={style.social_link} href="https://www.twitter.com/" target="blank"><img src={twit_icon} alt='' /></a>
                    <a className={style.social_link} href="https://www.instagram.com/" target="blank"><img src={inst_icon} alt='' /></a>
                    <a className={style.social_link} href="https://www.linkedin.com/" target="blank"><img src={lnkd_icon} alt='' /></a>
                </div>
            </div>
            <div id={style.down}>
                <div id={style.link_container}>
                    <Link to='/'
                        onMouseEnter={() => {
                            setHome_style(onfocus)
                        }}
                        onMouseLeave={() => {
                            setHome_style(onblur)
                        }}
                        style={home_style}
                        className={ style.activeClassName  }
                    >
                            <HomeIcon className={style.link_icon}/>Home
                    </Link>
                    <hr className={style.hrl} />
                    <Link to='/products/all'
                        id={ style.product_link }
                        onMouseEnter={()=>{
                            setCategory_container_style(show);
                            setProduct_style(onfocus);
                        }} 
                        onMouseLeave={()=>{
                            setCategory_container_style(donotShow)
                            setProduct_style(onblur);
                        }}
                        style={product_style}
                        className={ style.activeClassName }
                    >
                        <div onClick={()=>{
                            setCategory_container_style(show);
                        }}>
                            Products<ArrowDownIcon className={style.link_icon}/>
                        </div>
                            
                        <div style={category_container_style} id={style.category_container} onMouseEnter={()=>setCategory_container_style(show)} onMouseLeave={()=>setCategory_container_style(donotShow)} >
                            <p id={style.category_container_close_button} onClick={()=>setCategory_container_style(donotShow)}>x close</p>
                            <Link className={style.category_link} to='/products/all'><p className={style.category} onClick={()=>setCategory_container_style(donotShow)}>All Products</p></Link>
                            <Link className={style.category_link} to='/products/automation'><p className={style.category} onClick={()=>setCategory_container_style(donotShow)}>I.4 Technology & Industrial Automation</p></Link>
                            <Link className={style.category_link} to='/products/communication'><p className={style.category}  onClick={()=>setCategory_container_style(donotShow)}>Electronic & Communication <br></br>  Engineering Equipments</p></Link>
                            <Link className={style.category_link} to='/products/electrical'><p className={style.category}  onClick={()=>setCategory_container_style(donotShow)}>Electrical Engineering Equipments</p></Link>
                            <Link className={style.category_link} to='/products/mechanical'><p className={style.category}  onClick={()=>setCategory_container_style(donotShow)}>Mechanical Engineering Equipment</p></Link>
                            <Link className={style.category_link} to='/products/civil'><p className={style.category}  onClick={()=>setCategory_container_style(donotShow)}>Civil Engineering Equipments</p></Link>
                            <Link className={style.category_link} to='/products/sensor'><p className={style.category}  onClick={()=>setCategory_container_style(donotShow)}>Measuring Equipments & Sensor</p></Link>
                            <Link className={style.category_link} to='/products/tools'><p className={style.category}  onClick={()=>setCategory_container_style(donotShow)}>Tools & Devices</p></Link>
                        </div>
                    </Link>
                    <hr className={style.hrl} />
                    <Link to='/about'
                        onMouseEnter={() => {
                            setAbout_style(onfocus)
                        }}
                        onMouseLeave={() => {
                            setAbout_style(onblur)
                        }}
                        style={about_style}
                        className={ style.activeClassName }
                    >
                            <InformationCircleIcon className={style.link_icon}/>About
                    </Link>
                    <hr className={style.hrl} />
                    <Link to='/contact'
                        onMouseEnter={() => {
                            setContact_style(onfocus)
                        }}
                        onMouseLeave={() => {
                            setContact_style(onblur)
                        }}
                        style={contact_style}
                        className={ style.activeClassName }
                    >
                            <MapPinIcon className={style.link_icon}/>Contact
                    </Link>
                </div>
                <Link to='/' id={style.logo_container}>
                    <img src={logo} alt='' />
                </Link>
            </div>
        </div>
    );
};

export default ShopHeader;