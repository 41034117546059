import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from '../../components/Shared/AdminHeader/AdminHeader';
import { AuthContext } from '../../Context/UserContext';

const Admin = () => {
    const admins = [ 'sabur.ipe19@gmail.com' ];
    const {user} = useContext(AuthContext);
    if( !admins.includes(user.email) ){
        return <h1>4O4 Page not Found</h1>
    }
    return (
        <div>
            <AdminHeader></AdminHeader>
            <Outlet></Outlet>
        </div>
    );
};

export default Admin;