import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import ShopFooter from '../../components/Shared/ShopFooter/ShopFooter';
import ShopHeader from '../../components/Shared/ShopHeader/ShopHeader';
import style from './Shop.module.css';

const Shop = () => {
    return (
        <div>
            <Helmet>
                <title>I4technologiesBD</title>    
                <meta name="description" content="Industrial Automation Training System was developed in direct response to industry demand for people with broad skills and knowledge in digital electronics."></meta>
                <meta name="keywords" content="mechanical automation, pneumatic, drives, electronics, industrial safety, handling technology, sensor applications, vacuum technology "></meta>
            </Helmet>
            <div id={style.outlet_container}>
                <Outlet></Outlet>
            </div>
            <div id={style.footer_container}>
                <ShopFooter></ShopFooter>
            </div>
            <div id={style.header_container}>
                <ShopHeader></ShopHeader>
            </div>
        </div>
    );
};

export default Shop;