import React from 'react';
import { useLoaderData } from 'react-router-dom';
import style from './ViewMemberAdmin.module.css';
import Member from './Member/Member';

const ViewMemberAdmin = () => {
    const all_members = useLoaderData();
    return (
        <div id={style.view_member}>
            {
                all_members.map( member => <Member key={member._id} member={member}></Member> )
            }
        </div>
    );
};

export default ViewMemberAdmin;