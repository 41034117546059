import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBBSieazCbGZx4Bxq_ub1-gQXNm9aZ1UqA",
  authDomain: "i4technologiesbd2023.firebaseapp.com",
  projectId: "i4technologiesbd2023",
  storageBucket: "i4technologiesbd2023.appspot.com",
  messagingSenderId: "1007374911661",
  appId: "1:1007374911661:web:95b918391c98ce8842de24"
};

const app = initializeApp(firebaseConfig);

export default app;