import React from 'react';
import style from './Contact.module.css';
import { HomeIcon } from '@heroicons/react/24/solid';

const Contact = () => {
    return (
        <div id={style.contact_page}>
            <div id={style.contact}>
                <div id={style.contact_form}>
                    <p id={style.form_title}>MESSAGE YOUR REQUIREMENT</p>
                    <div className={style.form_section}>
                        <input id={style.first_name} disabled placeholder='First Name'></input>
                        <input id={style.last_name} disabled placeholder='Second Name'></input>
                    </div>
                    <div className={style.form_section}>
                        <input id={style.mobile} disabled placeholder='Mobile'></input>
                        <input id={style.email} disabled placeholder='Email Address'></input>
                    </div>
                    <div className={style.form_section}>
                        <textarea id={style.message} disabled placeholder='Your Message Here'></textarea>
                    </div>
                    <div className={style.form_section} id={style.button_container}>
                        <button id={style.button} disabled>Submit</button>
                    </div>
                </div>
                <div id={style.contact_info}>
                    <p id={style.contact_title}>GET IN TOUCH</p>
                    <div className={style.contact_section}>
                        <div className={style.icon_container}>
                            <svg className={style.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className={style.contact_text}>
                            <p className={style.contact_type}>Phone</p>
                            <p className={style.contact_description}>+88 01918000690</p>
                        </div>
                    </div>
                    <div className={style.contact_section}>
                        <div className={style.icon_container}>
                            <svg className={style.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                            </svg>
                        </div>
                        <div className={style.contact_text}>
                            <p className={style.contact_type}>Email</p>
                            <p className={style.contact_description}>i4technologiesbd@gmail.com</p>
                        </div>
                    </div>
                    <div className={style.contact_section}>
                        <div className={style.icon_container}>
                            <HomeIcon className={style.icon}></HomeIcon>
                        </div>
                        <div className={style.contact_text}>
                            <p className={style.contact_type}>Address</p>
                            <p className={style.contact_description}>Nawab Road, Sheikhghat, Sylhet-3100</p>
                        </div>
                    </div>
                </div>
            </div>
            <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d904.8120983799357!2d91.85425755595716!3d24.889507542488346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3751ab552407297b%3A0xbb9dbf0d1656f1c2!2sGreen%20BD!5e0!3m2!1sen!2sbd!4v1671790717505!5m2!1sen!2sbd" width="90%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
        </div>
    );
};

export default Contact;