import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import style from './Home.module.css';

const Home = () => {
    const images = useLoaderData();
    return (
        <div>
            <div id={style.carousel_container}>
                <Carousel>
                    {
                        images.map( img => <Carousel.Item interval={5000}><img className="d-block w-100" src={img.img} alt={img._id} /></Carousel.Item> )
                    }
                </Carousel>
            </div>
        </div>
    );
};

export default Home;