import React from 'react';
import { useLoaderData } from 'react-router-dom';
import mail_icon from '../../../images/icons/mail.svg';
import style from './MemberDetails.module.css';

const MemberDetails = () => {
    const details = useLoaderData();

    const { image , name , designation , mobile , email, message,  description } = details;

    return (
        <div id={style.member_details}>
            <div id={style.right_space}></div>
            <div id={style.middle}>
                <div id={style.up}>
                    <div id={style.left}>
                        <img src={image} alt="Member Image is Here" />
                    </div>
                    <div id={style.right}>
                        <p id={style.name}>{name}</p>
                        <p id={style.designation}>{designation}</p>


                        <div id={style.contact_container}>
                            <svg id={style.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                            </svg>
                            <p id={style.mobile}>{mobile}</p>
                        </div>


                        <div id={style.email_container}>
                            <img id={style.email_icon} src={mail_icon} alt="" />
                            <p id={style.email}> {email}</p>
                        </div>


                        <p id={style.massage}>{message}</p>
                    </div>
                </div>
                <div id={style.down}>
                    <p id={style.details_title}>Personal details: </p>
                    <img src={description} alt="personal details" />
                </div>
            </div>
            <div id={style.left_space}></div>
        </div>
    );
};

export default MemberDetails;