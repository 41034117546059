import React from 'react';
import style from './AdminHeader.module.css';
import { Link } from 'react-router-dom';

const AdminHeader = () => {
    return (
        <div id={style.header}>
            <p id={style.title}>Only Admin are allowed</p>
            <div id={style.link_container}>
                <Link className={style.link} to = '/admin/'>See Product</Link>
                <Link className={style.link} to = '/admin/add_product'>Add Product</Link>
                <Link className={style.link} to = '/admin/see_member'>See Members</Link>
                <Link className={style.link} to = '/admin/add_member'>Add Member</Link>
                <Link className={style.link} to = '/admin/see_picture'>See Pictures</Link>
                <Link className={style.link} to = '/admin/add_picture'>Add Picture</Link>
            </div>
        </div>
    );
};

export default AdminHeader;