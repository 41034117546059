import React from 'react';
import { Link } from 'react-router-dom';
import style from './Member.module.css';

const Member = ({member}) => {
    const { name, _id, image } = member;
    return (
        <div id={style.container}>
            <div id={style.up}>
                <div id={style.image_container}>
                    <img src={image} alt={name} />
                </div>
                <Link id={style.link} to={`/admin/check_member/${_id}`}>Go for check</Link>
            </div>
            <p className={style.text}>{name}</p>
            <p className={style.text}>{_id}</p>
        </div>
    );
};

export default Member;