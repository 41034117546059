import React, { createContext, useEffect, useState } from 'react';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import app from '../firebase/firebase.config';

export const AuthContext = createContext();
const auth = getAuth(app);

const UserContext = ({children}) => {
    const [ user, setUser ] = useState({});
    const provider = new GoogleAuthProvider();

    const LogIn = () => {
        return signInWithPopup(auth, provider);
    }

    useEffect( () => {
        onAuthStateChanged( auth , currentUser => {
            setUser(currentUser);
        })
    } , [] )

    const authInfo = { user , LogIn };
    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default UserContext;