import React, { useEffect, useState } from 'react';
import style from './ProductInShop.module.css';
import Product from './Product/Product';
import { useParams } from 'react-router-dom';

const ProductInShop = () => {
    const [ products , setProducts ] = useState([]);
    const [ count , setCount ] = useState(0);
    const [ currentpage , setCurrentpage ] = useState(0);
    const pagecount = Math.ceil(count/9);
    const { id } = useParams();

    useEffect( () => {
        fetch(`https://i4technologiesbd.vercel.app/products/${id}?page=${currentpage}`)
        .then( res => res.json() )
        .then( data => {
            setProducts(data.products);
            setCount(data.count);
        } )
    } , [currentpage, id])

    return (
        <div id={style.product_viewer}>
            <div id={style.products_conatiner}>
                {
                    products.map( product => <Product key={product._id} product={product}></Product>)
                }
            </div>
            <div id={style.button_container}>
                {
                    [...Array(pagecount).keys()].map( number => <button onClick={() => setCurrentpage(number)} className={ number===currentpage ? style.selected : style.unselected }>{number+1}</button>)
                }
            </div>
        </div>
    );
};

export default ProductInShop;