import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import style from './CheckMember.module.css';

const CheckMember = () => {
    const details = useLoaderData();
    const { _id, name, image, designation, mobile, email, message, description } = details;
    const navigate = useNavigate();
    const handleDelete = (event) => {
        event.preventDefault();
        fetch(`https://i4technologiesbd.vercel.app/delete_member/${_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if( !response.ok ) {
                console.log("Something Wrong");
            }
        } )
        .then( data => console.log(data) )
        navigate('/admin/see_member');
    }
    return (
        <div id={style.add_member}>
            <div id={style.output}>
                <div id={style.up}>
                    <div id={style.left}>
                        <img src={image} alt="Member Image is Here" />
                    </div>
                    <div id={style.right}>
                        <p id={style.name}>{name}</p>
                        <p id={style.designation}>{designation}</p>
                        <p id={style.mobile}>Mobile: {mobile}</p>
                        <p id={style.email}>Email: {email}</p>
                        <p id={style.massage}>Massage: {message}</p>
                    </div>
                </div>
                <div id={style.down}>
                    <p id={style.details_title}>Personal details: </p>
                    <img src={description} alt="personal details" />
                </div>
            </div>
            <div id={style.input}>
                <button id={style.delete_button} onClick={handleDelete} >Delete this from DataBase</button>
            </div>
        </div>
    );
};

export default CheckMember;