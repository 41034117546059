import React, { useState } from 'react';
import style from './AddProduct.module.css';

const AddProduct = () => {
    const [ product_name , setProduct_name ] = useState('');
    const [ product_description , setProduct_description ] = useState('');
    const [ image_url , setImage_url ] = useState('');
    const [ price , setPrice ] = useState('');
    const [ seodescription , setSeodescription ] = useState('');
    const [ optional_message , setOptional_message ] = useState('');
    const [ seokeywords , setSeokeywords ] = useState('');
    const [ model , setModel ] = useState('');
    const [ brand , setBrand ] = useState('');

    const [i4_technnology_industrial_automation, setI4_technnology_industrial_automation] = useState(false);
    const [electronic_and_Communication_Engineering_Equipments_and_Trainer_kits, setelectronic_and_Communication_Engineering_Equipments_and_Trainer_kits] = useState(false);
    const [electrical_Engineering_Equipments_Trainer_kits , setElectrical_Engineering_Equipments_Trainer_kits] = useState(false);
    const [mechanical_Engineering_Equipment_and_Trainer_kits , setMechanical_Engineering_Equipment_and_Trainer_kits] = useState(false);
    const [civil_Engineering_Equipments_and_Trainer_kits , setCivil_Engineering_Equipments_and_Trainer_kits] = useState(false);
    const [measuring_Equipments_and_Sensor , setMeasuring_Equipments_and_Sensor] = useState(false);
    const [tools_and_Devices , setTools_and_Devices] = useState(false);

    const handle_I4_technnology_industrial_automation = () => { setI4_technnology_industrial_automation(!i4_technnology_industrial_automation)};
    const handle_Electronic_and_Communication_Engineering_Equipments_and_Trainer_kits = () => { setelectronic_and_Communication_Engineering_Equipments_and_Trainer_kits(!electronic_and_Communication_Engineering_Equipments_and_Trainer_kits)};
    const handle_Electrical_Engineering_Equipments_Trainer_kits = () => { setElectrical_Engineering_Equipments_Trainer_kits(!electrical_Engineering_Equipments_Trainer_kits)};
    const handle_Mechanical_Engineering_Equipment_and_Trainer_kits = () => { setMechanical_Engineering_Equipment_and_Trainer_kits(!mechanical_Engineering_Equipment_and_Trainer_kits)};
    const handle_Civil_Engineering_Equipments_and_Trainer_kits = () => { setCivil_Engineering_Equipments_and_Trainer_kits(!civil_Engineering_Equipments_and_Trainer_kits)};
    const handle_Measuring_Equipments_and_Sensor = () => { setMeasuring_Equipments_and_Sensor(!measuring_Equipments_and_Sensor)};
    const handle_Tools_and_Devices = () => { setTools_and_Devices(!tools_and_Devices)};


    const handle_add_to_db = (event) => {
        event.preventDefault()
        const item = { name: product_name, description: product_description, img: image_url, price: price, seodescription: seodescription, seokeywords: seokeywords, model: model, brand: brand, optional_message: optional_message,
            automation: i4_technnology_industrial_automation,
            communication: electronic_and_Communication_Engineering_Equipments_and_Trainer_kits,
            electrical: electrical_Engineering_Equipments_Trainer_kits,
            mechanical: mechanical_Engineering_Equipment_and_Trainer_kits,
            civil: civil_Engineering_Equipments_and_Trainer_kits,
            sensor: measuring_Equipments_and_Sensor,
            tools: tools_and_Devices
        }
        fetch('https://i4technologiesbd.vercel.app/add_item/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(item),
        })
        .then((response) => response.json())
        .then((data) => {
            window.location.reload();
        })
    }
    return (
        <div id={style.container}>
            <div id={style.info_container}>
                <div id={style.info_up}>
                    <div id={style.image_container}>
                        <img src={image_url} alt='Image URL here' />
                    </div>
                    <div id={style.basic_info} >
                        <p id={style.product_name}>{product_name}</p>
                        <p id={style.product_name}>Brand: {brand}</p>
                        <p id={style.product_name}>Model: {model}</p>
                        <p id={style.product_price}>Price: {price}</p>
                        <p id={style.categori_title}>Category</p>
                        <div id={style.product_category_container}>
                            <div><input type="checkbox" checked={i4_technnology_industrial_automation} onChange={handle_I4_technnology_industrial_automation}/>I.4 Technology & Industrial Automation</div>
                            <div><input type="checkbox" checked={electronic_and_Communication_Engineering_Equipments_and_Trainer_kits} onChange={handle_Electronic_and_Communication_Engineering_Equipments_and_Trainer_kits}/>Electronic & Communication Engineering Equipments & Trainer kits</div>
                            <div><input type="checkbox" checked={electrical_Engineering_Equipments_Trainer_kits} onChange={handle_Electrical_Engineering_Equipments_Trainer_kits}/>Electrical Engineering Equipments & Trainer kits </div>
                            <div><input type="checkbox" checked={mechanical_Engineering_Equipment_and_Trainer_kits} onChange={handle_Mechanical_Engineering_Equipment_and_Trainer_kits}/>Mechanical Engineering Equipment & Trainer kits</div>
                            <div><input type="checkbox" checked={civil_Engineering_Equipments_and_Trainer_kits} onChange={handle_Civil_Engineering_Equipments_and_Trainer_kits}/>Civil Engineering Equipments & Trainer kits </div>
                            <div><input type="checkbox" checked={measuring_Equipments_and_Sensor} onChange={handle_Measuring_Equipments_and_Sensor}/>Measuring Equipments & Sensor</div>
                            <div><input type="checkbox" checked={tools_and_Devices} onChange={handle_Tools_and_Devices}/>Tools & Devices</div>
                        </div>
                        <p id={style.optional_message}>{optional_message}</p>
                    </div>
                </div>
                <div id={style.seo_info}>
                    <hr className={style.hr_line} />
                    <p className={style.seo_title}>SEO Description</p>
                    <p className={style.seo_paragraph}>{seodescription}</p>
                    <p className={style.seo_title}>SEO KeyWord</p>
                    <p className={style.seo_paragraph}>{seokeywords}</p>
                    <hr className={style.hr_line} />
                </div>
                <div id={style.info_down}>
                    <p id={style.description_title}>Description:</p>
                    <img src={product_description} alt="product description here"/>
                </div>
            </div>
            <div id={style.others}>
                <div id={style.input_form}>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter Product Name</p>
                        <input id={style.product_name_input} type='text' onChange={ e => setProduct_name(e.target.value) }></input>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter Product Image Url</p>
                        <input id={style.product_image_input} type='text' onChange={ e => setImage_url(e.target.value) }></input>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter Product Price</p>
                        <input id={style.product_image_input} type='text' onChange={ e => setPrice(e.target.value) }></input>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter Product Brand</p>
                        <input id={style.product_image_input} type='text' onChange={ e => setBrand(e.target.value) }></input>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter Product Model</p>
                        <input id={style.product_image_input} type='text' onChange={ e => setModel(e.target.value) }></input>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter Product Description</p>
                        <textarea id={style.product_description_input} type='text' onChange={ e => setProduct_description(e.target.value) }></textarea>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter Optional Message</p>
                        <textarea id={style.product_description_input} type='text' onChange={ e => setOptional_message(e.target.value) }></textarea>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter SEO Description</p>
                        <textarea id={style.product_description_input} type='text' onChange={ e => setSeodescription(e.target.value) }></textarea>
                    </div>
                    <div className={style.input_label}>
                        <p className={style.input_title}>Enter SEO Keywords</p>
                        <textarea id={style.product_description_input} type='text' onChange={ e => setSeokeywords(e.target.value) }></textarea>
                    </div>
                </div>
                <button onClick={handle_add_to_db} id={style.add_button}>Add to DataBase</button>
            </div>
        </div>
    );
};

export default AddProduct;