import React, { useEffect, useState } from 'react';
import style from './About.module.css';
import Member from './Member/Member';

const About = () => {
    const [ members, setMembers ] = useState([]);
    const [ image , setImage ] = useState("");
    useEffect( () => {
        fetch( "https://i4technologiesbd.vercel.app/allmember")
        .then( res => res.json() )
        .then( data => setMembers(data) )
    } , [] );
    useEffect( () => {
        fetch( "https://i4technologiesbd.vercel.app/about/")
        .then( res => res.json() )
        .then( data => setImage(data[0].img) )
    } , [] );
    return (
        <div id={style.about}>
            <p id={style.title_up}>ABOUT I4TECHNOLOGIES</p>
            <div id={style.about_image_container}>
                <img src={image} alt="" />
            </div>
            <p id={style.title_down}>Team Members</p>
            <div id={style.members_container}>
                {
                    members.map( member => <Member key={member._id} member={member}></Member> )
                }
            </div>
        </div>
    );
};

export default About;