import React from 'react';
import style from './ShopFooter.module.css';
import logo from '../../../images/logo2.svg';
import phone_icon from '../../../images/icons/phone.svg';
import mail_icon from '../../../images/icons/mail.svg';
import { HomeIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

const ShopFooter = () => {
    return (
        <div id={style.footer}>
            <div className={style.item}>
                <div className={style.logo}>
                    <img src={logo} alt="" />
                </div>
                <p className={style.item_content}>Quality in a product or service is not what the supplier puts in. it is what the customer gets out and is willing to pay for. A product is not quality because it is hard to make and costs a lot of money, as manufacturers typically believe.</p>
            </div>
            <div className={style.item}>
                <p className={style.title}>GET IN TOUCH</p>
                <div className={style.item_content_container}><HomeIcon className={style.link_icon}/><p className={style.item_content}>Nawab Road, Sheikhghat, Sylhet-3100</p></div>
                <div className={style.item_content_container}><img src={phone_icon} alt='' /><p className={style.item_content}>+88 01918000690</p></div>
                <div className={style.item_content_container}><img src={mail_icon} alt='' /><p className={style.item_content}>i4technologiesbd@gmail.com</p></div>
            </div>
            <div className={style.item}>
                <p className={style.title}>IMPORTANT LINKS</p>
                <Link className={style.item_content} to='/'>Home</Link>
                <Link className={style.item_content} to='/products/all'>Products</Link>
                <Link className={style.item_content} to='/about'>About</Link>
                <Link className={style.item_content} to='/contact'>Contact</Link>
            </div>
        </div>
    );
};

export default ShopFooter;