import React from 'react';
import { useLoaderData } from 'react-router-dom';
import style from './ViewPictureAdmin.module.css';

const ViewPictureAdmin = () => {
    const pictures = useLoaderData();
    return (
        <div>
            {
                pictures.map( pic => <Picture key={pic._id} picture={pic}></Picture> )
            }
        </div>
    );
};

const Picture = ({picture}) => {
    const { _id , img } = picture;
    const handleDelete = (event) => {
        event.preventDefault();
        console.log("delete Button Clicked");
        fetch(`https://i4technologiesbd.vercel.app/delete_image/${_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if( !response.ok ) {
                console.log("Something Wrong");
            }
        } )
        .then( data => {
            window.location.reload();
        } )
    }
    return (
        <div id={style.picture}>
            <div id={style.image_container}>
                <img src={img} alt='Carousel_Image' />
            </div>
            <div id={ style.button_container }>
                <button onClick={handleDelete} id={style.delete_button}>Delete This Picture</button>
                <p id={style.button_text}>{_id}</p>
            </div>
        </div>
    )
}

export default ViewPictureAdmin;