import React, { useState } from 'react';
import style from './AddPicture.module.css';

const AddPicture = () => {
    const [ image , setImage ] = useState('');
    const handle_add_to_db = (event) => {
        event.preventDefault()
        const item = { img: image };
        fetch('https://i4technologiesbd.vercel.app/add_image/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(item),
        })
        .then((response) => response.json())
        .then((data) => {
            window.location.reload();
        })
    }
    return (
        <div id={style.add_picture}>
            <div id={style.input_section}>
                <p id={style.input_title}>Enter Image URL Here :</p>
                <input onChange={e => setImage(e.target.value)}></input>
                <button onClick={handle_add_to_db} id={style.input_button}>Add Image to DataBase</button>
            </div>
            <div id={style.image_container}>
                <img src={image} alt="Add Carousel Image Here" />
            </div>
        </div>
    );
};

export default AddPicture;