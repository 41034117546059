import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Product from './Product/Product';
import style from './ViewProductAdmin.module.css';

const ViewProductAdmin = () => {
    const all_products = useLoaderData();
    return (
        <div id={style.view_product}>
            {
                all_products.map( product => <Product key={product._id} product={product}></Product> )
            }
        </div>
    );
};

export default ViewProductAdmin;