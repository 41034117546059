import React from 'react';
import { Link } from 'react-router-dom';
import style from './Product.module.css';

const Product = ({ product }) => {
    const { name, _id, img } = product;
    return (
        <div id={style.container}>
            <div id={style.up}>
                <div id={style.image_container}>
                    <img src={img} alt={name} />
                </div>
                <Link id={style.link} to={`/admin/check_product/${_id}`}>Go for check</Link>
            </div>
            <p className={style.text}>{name}</p>
            <p className={style.text}>{_id}</p>
        </div>
    );
};

export default Product;