import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import style from './CheckProduct.module.css';

const CheckProduct = () => {
    const details = useLoaderData();
    const {_id, img, name, price, description, automation, communication, electrical, mechanical, civil, sensor, tools, seodescription, seokeywords, model} = details;
    const navigate = useNavigate();
    const handleDelete = (event) => {
        event.preventDefault();
        fetch(`https://i4technologiesbd.vercel.app/delete_item/${_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if( !response.ok ) {
                console.log("Something Wrong");
            }
        } )
        .then( data => console.log(data) )
        navigate('/admin');
    }

    return (
        <div id={style.container}>
            <div id={style.info_container}>
                <div id={style.info_up}>
                    <div id={style.image_container}>
                        <img src={img} alt='' />
                    </div>
                    <div id={style.basic_info} >
                        <p id={style.product_name}>{name}</p>
                        <p id={style.product_price}>Price: {price}</p>
                        <p id={style.product_model}>Model: {model}</p>
                        <p id={style.categori_title}>Categori</p>
                        {
                            automation ? <p className={style.categori}>I.4 Technology & Industrial Automation</p> : null
                        }
                        {
                            communication ? <p className={style.categori}>Electronic & Communication Engineering Equipments & Trainer kits</p> : null
                        }
                        {
                            electrical ? <p className={style.categori}>Electrical Engineering Equipments & Trainer kits</p> : null
                        }
                        {
                            mechanical ? <p className={style.categori}>Mechanical Engineering Equipment & Trainer kits</p> : null
                        }
                        {
                            civil ? <p className={style.categori}>Civil Engineering Equipments & Trainer kits</p> : null
                        }
                        {
                            sensor ? <p className={style.categori}>Measuring Equipments & Sensor</p> : null
                        }
                        {
                            tools ? <p className={style.categori}>Tools & Devices</p> : null
                        }
                        <p id={style.seo_description_title}>SEO Description</p>
                        <p id={style.seo_description}>{seodescription}</p>
                        <p id={style.seo_keyword_title}>SEO KeyWord</p>
                        <p id={style.seo_keyword}>{seokeywords}</p>
                    </div>
                </div>
                <div id={style.info_down}>
                    <img src={description} alt={name} />
                </div>
            </div>
            <div id={style.others}>
                <button id={style.delete_button} onClick={handleDelete} >Delete this from DataBase</button>
            </div>
        </div>
    );
};

export default CheckProduct;